<template>
  <div class="handle-button" style="display: inline">
    <el-button :disabled="disable" @click='handleClick'>
      批量修改对接人
    </el-button>
    <el-dialog title="修改对接人" :visible.sync='visible' width='500px' center custom-class='modify-contact-dialog'>
      <el-form ref='form' :model='form' label-width='80px' size='medium'>
        <el-form-item label="勾选品牌">
          {{ ids.length }}个
        </el-form-item>
        <el-form-item label="对接人" prop='contact_name'
                      :rules="{ required: true, message: '请输入对接人', trigger: 'blur' }">
          <el-input v-model='form.contact_name' placeholder='请输入对接人' maxlength='8' show-word-limit
                    style='width: calc(100% - 46px)' />
        </el-form-item>
      </el-form>
      <div style="display: flex;align-items:center;width: calc(100% - 106px);padding-left: 60px;color: #999999">
        <i class="el-icon-warning" style="font-size: 24px;color: coral;margin-right: 4px;" />
        <div v-if="form.contact_name">
          此操作将会把勾选品牌（{{ ids.length }}个），交接给【{{ form.contact_name }}】，
          操作后将无法撤回，是否继续？
        </div>
        <div v-else>
          请输入新的对接人
        </div>
      </div>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='visible = false' size='medium'>取 消</el-button>
        <ml-button text='确 定' size='medium' @click.native='handleSubmit' />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MlButton from '@/components/MlButton/index.vue'

export default {
  name: 'BJModifyContact',
  components: { MlButton },
  props: {
    disable: Boolean,
    ids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      form: {
        contact_name: null
      }
    }
  },
  methods: {
    handleClick: function() {
      if (!Array.isArray(this.ids) || !this.ids.length) {
        this.$message.warning('请选择要修改的数据')
        return
      }
      this.form.contact_name = null
      this.visible = true
    },
    handleSubmit: async function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = {
            ids: this.ids,
            contact_name: this.form.contact_name
          }
          const loading = this.$loading({
            lock: true,
            text: '批量修改对接人，请稍后',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)'
          })
          const response = await this.$api.modifyContactBrandJoinRecords(params)
          loading.close()
          if (response) {
            this.visible = false
            this.$message.success('操作成功')
            this.$emit('success')
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">

</style>
